import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// import Img from 'gatsby-image'

import Layout from '../components/Layout'

import './index.scss'
import Img from 'gatsby-image'
import ChevronLeft from '../assets/chevron-left.svg'
import { RiVideoLine } from 'react-icons/ri'
import { RiCloseLine } from 'react-icons/ri'

const Dashboard = () => {
    const data = useStaticQuery(graphql`
        query {
            allProjectsJson {
                nodes {
                    title
                    description
                    video
                    src {
                        childImageSharp {
                            fluid(maxWidth: 1920) {
                                src
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <Layout
            title="Promoting, Professionalising and accelerating youth culture across europe"
            body={[
                'Our portfolio is a selection of projects we and our network of professionals have worked on since 2007. Ministry of Urban Culture is the culmination of that work and our next step into solidifying efforts on an international scale.'
            ]}
            currentlyActiveTab="projects"
        >
            <div className="index">
                {data.allProjectsJson.nodes.map(node => (
                    <Element node={node} key={node.title.replace(' ', '-')} />
                ))}
            </div>
        </Layout>
    )
}

const Element = ({ node }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="index__project">
            <div className="modalwrapper">
                    {isOpen && (
                        <div setIsOpen={setIsOpen}>
                            <iframe
                                title="video"
                                width="560"
                                height="315"
                                src={node.video}
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            />
                            <button
                                className="closeBtn"
                                onClick={() => setIsOpen(false)}
                                onKeyDown={() => setIsOpen(false)}
                                
                            >
                                <RiCloseLine
                                    style={{
                                        marginBottom: '-3px'
                                    }}
                                />
                            </button>
                        </div>
                    )}
                </div>
            <Img
                className="index__project__image"
                fluid={node.src.childImageSharp.fluid}
            />
            <div className="index__project__content">
                <h3 className="index__project__content__title">{node.title}</h3>
                <RiVideoLine
                    className="index__project__content__icon"
                    onClick={() => setIsOpen(true)}
                    onKeyDown={() => setIsOpen(true)}
                    role="button"
                />
            </div>
            <div className="index__project__description">
                {node.description}
            </div>
            <div className="index__project__arrow">
                <ChevronLeft />
            </div>
        </div>
    )
}

export default Dashboard